<template>
  <div>
    <div id="logo">
      <el-image :src="require(`@/assets/img/logo_black.png`)" fit="contain"></el-image>
    </div>
    <div id="tabs">
      <el-tabs v-model="type">
        <el-tab-pane label="用户服务协议" name="1">
          <iframe src="/tos/UserServiceAgreement.html" frameborder="0" height="100%" width="100%"></iframe>
        </el-tab-pane>
        <el-tab-pane label="隐私政策" name="2">
          <iframe src="/tos/PrivacyPolicy.html" frameborder="0" height="100%" width="100%"></iframe>
        </el-tab-pane>
        <el-tab-pane label="用户注销协议" name="3">
          <iframe src="/tos/UserLogoutProtocol.html" frameborder="0" height="100%" width="100%"></iframe>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TermsOfService',
  data() {
    return {
      type: '1'
    }
  },
  created() {
    this.type = this.$route.query.type || '1'
  }
}
</script>

<style lang="scss" scoped>
div {
  box-sizing: border-box;
}
#logo {
  width: 200px;
  padding: 20px 20px 0 20px;
}
#tabs {
  padding: 0 20px 20px 20px;
}
::v-deep .el-tabs__content {
  height: calc(100vh - 175px);
  overflow: auto;
}
.el-tab-pane {
  height: 99%;
}
</style>
